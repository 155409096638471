import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';
import type {KeyedArray} from '@/modules/foundation/shared/types';

type Address = {
	streetName?: string;
	postalCode?: string;
	city?: string;
};

type Organization = {
	organizationNumber?: string;
	organizationName?: string;
	address?: Address;
	phoneNumber?: string;
	email?: string;
};

type FooterLink = {
	href?: string;
	title?: string;
	targetBlank?: boolean;
};

export type FooterProps = {
	organization?: Organization;
	links?: KeyedArray<FooterLink>;
	privacyPage?: {
		title?: string;
		href?: string;
	};
};

export const Footer: FC<FooterProps> = ({links, organization, privacyPage}) => {
	const {address, phoneNumber, email, organizationNumber, organizationName} = organization || {};

	return (
		<Container
			as="footer"
			className={clsx(
				'pt-11',
				'md:pt-24',
				'pb-6',
				'md:pb-[72px]',
				'bg-primary',
				'text-beige-100',
			)}
		>
			<div
				className={clsx(
					'flex',
					'flex-col',
					'md:flex-row',
					'justify-between',
					'items-start',
					'gap-20',
				)}
			>
				<Link className={clsx('block', 'group')} href="/">
					<span className="sr-only">Forside</span>
					<Image
						src="/logo/vaag-logo-circle.svg"
						alt=""
						width={95}
						height={95}
						className={clsx('shrink-0', 'animate-slowSpin')}
						sizes="95px"
					/>
				</Link>

				<div
					className={clsx(
						'flex',
						'flex-col',
						'md:flex-row',
						'gap-x-14',
						'gap-y-10',
						'mb-12',
						'max-md:w-full',
					)}
				>
					{(organizationName || address || organizationNumber) && (
						<div>
							{organizationName && <FooterHeading>{organizationName}</FooterHeading>}

							{address && (
								<Paragraph>
									<span>{address.streetName}</span>,{' '}
									<span>{address.postalCode}</span> <span>{address.city}</span>
								</Paragraph>
							)}

							{organizationNumber && (
								<Paragraph>Org. nr: {organizationNumber}</Paragraph>
							)}
						</div>
					)}

					{(email || phoneNumber) && (
						<div>
							<FooterHeading>Kontakt oss</FooterHeading>
							{email && (
								<>
									<span className="sr-only">Epost</span>
									<Paragraph asChild>
										<Link href={`mailto:${email.replaceAll(' ', '')}`}>
											{email}
										</Link>
									</Paragraph>
								</>
							)}

							{phoneNumber && (
								<div>
									<span className="sr-only">Telefon:</span>
									<Link href={createPhoneLink(phoneNumber)}>
										{formatPhoneNumber(phoneNumber)}
									</Link>
								</div>
							)}
						</div>
					)}

					<div className={clsx('flex', 'justify-between', 'gap-10', 'flex-wrap')}>
						{links?.length && (
							<ul className={clsx('gap-y-2', 'flex', 'flex-col', 'md:hidden')}>
								{links.map((link) => {
									return (
										<li key={link._key}>
											<Link
												href={link.href ?? '#'}
												{...(link.targetBlank && {target: '_blank'})}
												className={clsx(
													'hover:opacity-50',
													'transition-opacity',
												)}
											>
												{link.title}
											</Link>
										</li>
									);
								})}
							</ul>
						)}

						{/* Google partner */}
						<Link
							href="https://www.google.com/partners/agency?id=5507474333"
							target="_blank"
							className={clsx('w-[75px]', 'md:w-[80px]', 'mt-1', 'inline-block')}
						>
							<Image
								src="https://www.gstatic.com/partners/badge/images/2024/PartnerBadgeClickable.svg"
								alt="Google partner"
								width={80}
								height={80}
							/>
						</Link>
					</div>
				</div>
			</div>

			<div
				className={clsx(
					'border-t',
					'border-secondary',
					'pt-5',
					'md:flex',
					'md:justify-between',
					'flex-wrap-reverse',
					'gap-x-10',
					'gap-y-8',
				)}
			>
				<div
					className={clsx(
						'flex',
						'flex-col',
						'md:flex-row',
						'items-center',
						'gap-x-14',
						'gap-y-3',
					)}
				>
					{organizationName && (
						<Paragraph size="small" className="text-beige-100">
							&copy; {organizationName} {new Date().getFullYear()}
						</Paragraph>
					)}
					{privacyPage?.title && (
						<Paragraph size="small" asChild>
							<Link
								href={privacyPage?.href ?? '#'}
								className={clsx(
									'text-beige-100',
									'hover:text-beige-100/50',
									'transition-colors',
								)}
							>
								{privacyPage?.title}
							</Link>
						</Paragraph>
					)}
				</div>

				{links?.length && (
					<ul className={clsx('gap-x-10', 'hidden', 'md:flex')}>
						{links.map((link) => {
							return (
								<li key={link._key}>
									<Link
										href={link.href ?? '#'}
										className={clsx('hover:opacity-50', 'transition-opacity')}
										{...(link.targetBlank && {target: '_blank'})}
									>
										{link.title}
									</Link>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</Container>
	);
};

const FooterHeading = ({children}: PropsWithChildren) => {
	return (
		<Heading level={2} spacing size="xsmall" className={clsx('text-secondary')}>
			{children}
		</Heading>
	);
};
