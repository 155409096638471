import {groq} from 'next-sanity';

import {DOCUMENT_TYPE_FRONTPAGE} from '@/modules/page/sanity/frontpage/constants';
import {DOCUMENT_TYPE_PAGE} from '@/modules/page/sanity/page/constants';
import {DOCUMENT_TYPE_PROJECT} from '@/modules/vaag/project/sanity/constants';
import {DOCUMENT_TYPE_PROJECT_LANDING_PAGE} from '@/modules/vaag/project/sanity/project-landing-page-container/constants';
import {PROJECTS_PAGE_PATH} from '@/shared/constants';

import type {LinkableDocumentTypes} from './schema';

export const LINK_FRAGMENT = groq`
	type == "internal" => {
		...,
		reference-> {
			title,
			_type,
			"slug": slug.current,
		}
	},
	type == "external" => {
		...,
	}
`;

export type LinkFragment = {
	_type: 'link';
	href?: string;
	type?: 'internal' | 'external';
	targetBlank?: boolean;
	reference?: {
		title?: string;
		_type: LinkableDocumentTypes;
		slug?: string;
	};
};

export const internalLinkToHref = (documentType?: LinkableDocumentTypes, slug?: string) => {
	switch (documentType) {
		case DOCUMENT_TYPE_FRONTPAGE:
			return '/';
		case DOCUMENT_TYPE_PAGE:
			return slug ? `/${slug}` : '#';
		case DOCUMENT_TYPE_PROJECT:
			return slug ? `${PROJECTS_PAGE_PATH}/${slug}` : '#';
		case DOCUMENT_TYPE_PROJECT_LANDING_PAGE:
			return PROJECTS_PAGE_PATH;
		default:
			console.warn('Document type not a known linkable type:', documentType);
			return '#';
	}
};

export const linkFragmentToHref = (link: LinkFragment) => {
	if (link.type === 'internal') {
		return internalLinkToHref(link.reference?._type, link.reference?.slug);
	}

	if (link.type === 'external') {
		return link.href ?? '#';
	}

	return '#';
};
