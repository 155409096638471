'use client';

import {type FC, useState} from 'react';
import clsx from 'clsx';
import {useMotionValueEvent, useScroll} from 'framer-motion';

import {useHero} from '@/modules/vaag/context/hero-context';

interface Props {
	isOpen: boolean;
	onClick?: () => void;
}

const MENU_STRING = 'Meny';

export const Hamburger: FC<Props> = ({isOpen, onClick}) => {
	const {hasVideoHero} = useHero();
	const {scrollY} = useScroll();

	const [prev, setPrev] = useState(0);
	const [scrollIsAtTop, setScrollIsAtTop] = useState(true);

	const scrollMargin = 70;

	useMotionValueEvent(scrollY, 'change', (latest) => {
		if (latest > prev && latest > scrollMargin) {
			setScrollIsAtTop(false);
		}
		if (latest === 0) {
			setScrollIsAtTop(true);
		}
		setPrev(latest);
	});

	return (
		<button
			onClick={onClick}
			aria-expanded={isOpen}
			className={clsx(
				'group',
				'w-12',
				'h-12',
				'flex',
				'justify-center',
				'items-center',
				'z-50',
				'focus:outline-none',
				'focus-visible:shadow-none',
				'rounded',
				hasVideoHero && !isOpen
					? ['focus-visible:bg-beige-100/50']
					: ['focus-visible:bg-primary/50'],
			)}
		>
			<span className={clsx('sr-only')}>{MENU_STRING}</span>
			<div aria-hidden="true" className={clsx('space-y-3')}>
				<div
					className={clsx(
						'h-px',
						'w-8',
						hasVideoHero && !isOpen && scrollIsAtTop
							? 'bg-beige-100 group-focus-visible:bg-primary'
							: 'bg-primary group-focus-visible:bg-beige-100',
						'rounded-full',
						'transition-all',
						isOpen ? 'translate-x-0 translate-y-[6.5px] rotate-45' : '',
					)}
				/>
				<div
					className={clsx(
						'h-px',
						'w-8',
						hasVideoHero && !isOpen && scrollIsAtTop
							? 'bg-beige-100 group-focus-visible:bg-primary'
							: 'bg-primary group-focus-visible:bg-beige-100',
						'rounded-full',
						'transition-all',
						isOpen ? 'translate-x-0 translate-y-[-6.5px] -rotate-45' : '',
					)}
				/>
			</div>
		</button>
	);
};
