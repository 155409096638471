import {groq} from 'next-sanity';

import {LINK_FRAGMENT, type LinkFragment} from '@/modules/foundation/sanity/link/query';
import type {SanityArray} from '@/sanity/lib/types';

import {DOCUMENT_TYPE_SITE_SETTINGS} from './constants';
import type {SiteSettingsSchema} from './schema';

type LinkWithTitleFragment = LinkFragment & {
	title?: string;
};

export type NavLinkFragment = LinkWithTitleFragment & {
	featured?: boolean;
};

export const SITE_SETTINGS_FRAGMENT = groq`
	...,
	navigation[] {
		_type == "link" => {
			${LINK_FRAGMENT},
		},
	},
	footerLinks[] {
		${LINK_FRAGMENT}
	},
	privacyPageReference-> {
		title,
		_type,
		"slug": slug.current,
	},
`;

type NavigationFragment = SanityArray<NavLinkFragment>;

export type SiteSettingsFragment = Omit<
	SiteSettingsSchema,
	'navigation' | 'footerLinks' | 'privacyPageReference'
> & {
	navigation?: NavigationFragment;
	footerLinks?: SanityArray<LinkWithTitleFragment>;
	privacyPageReference?: {
		title?: string;
		_type?: string;
		slug?: string;
	};
};

export const SITE_SETTINGS_QUERY = groq`
	*[_type == "${DOCUMENT_TYPE_SITE_SETTINGS}"][0] {
		${SITE_SETTINGS_FRAGMENT}
	}
`;

export type SiteSettingsQueryResult = SiteSettingsFragment | null;
