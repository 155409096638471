import type {ComponentProps, FC} from 'react';
import NextLink from 'next/link';

import {cn} from '@/modules/foundation/shared/tailwind';

type AnchorPropsWithoutTarget = Omit<ComponentProps<'a'>, 'target'>;

export type LinkProps = AnchorPropsWithoutTarget & {
	targetBlank?: boolean;
};

export const Link: FC<LinkProps> = ({href, targetBlank, className, children, ...rest}) => {
	const linkShouldUseAnchorTag = checkIfLinkShouldUseAnchorTag(href);

	return (
		<>
			{linkShouldUseAnchorTag ? (
				<a
					href={href ?? '#'}
					{...(targetBlank ? {target: '_blank', rel: 'noopener noreferrer'} : {})}
					className={cn(['underline', 'hover:no-underline', className])}
					{...rest}
				>
					{children}
				</a>
			) : (
				<NextLink
					href={href ?? '#'}
					{...(targetBlank ? {target: '_blank', rel: 'noopener noreferrer'} : {})}
					className={cn(['underline', 'hover:no-underline', className])}
					{...rest}
				>
					{children}
				</NextLink>
			)}
		</>
	);
};

const checkIfLinkShouldUseAnchorTag = (href?: string) => {
	if (!href) return false;

	return (
		href?.startsWith('https://') ||
		href?.startsWith('http://') ||
		href?.startsWith('www.') ||
		href?.startsWith('mailto:') ||
		href?.startsWith('tel:')
	);
};
