import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';

import {cn} from '@/modules/foundation/shared/tailwind';

type HeadingProps = ComponentPropsWithoutRef<'h1'> & {
	/** Heading level. This will translate into any h1-6 level unless `asChild` is defined */
	level?: 1 | 2 | 3 | 4 | 5 | 6;
	/** Changes text sizing
	 * @default 'xlarge'
	 */
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
	/** Adds margin-bottom */
	spacing?: boolean;
	/**
	 * Change the default rendered element for the one passed as a child, merging their props and behavior.
	 * @default false
	 */
	asChild?: boolean;
};

/** Use `Heading` to render h1-6 elements with heading text styles.  */
export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
	({level = 1, size = 'xlarge', spacing = false, className, asChild, ...rest}, ref) => {
		const Component = asChild ? Slot : `h${level ?? 1}`;

		return (
			<Component
				ref={ref}
				className={cn(
					[
						size === 'xsmall' && ['text-xl', 'tracking-[-1%]'],
						size === 'small' && ['text-3xl'],
						size === 'medium' && ['text-4xl'],
						size === 'large' && ['text-5xl'],
						size === 'xlarge' && ['text-6xl'],
						spacing && [
							size === 'xsmall' && ['mb-3'],
							size === 'small' && ['mb-3'],
							size === 'medium' && ['mb-3'],
							size === 'large' && ['mb-3'],
							size === 'xlarge' && ['mb-3'],
						],
						'font-display',
						'font-bold',
					],
					className,
				)}
				{...rest}
			/>
		);
	},
);

Heading.displayName = 'Heading';
