'use client';

import type {FC} from 'react';
import {useCallback, useState} from 'react';
import {FocusOn} from 'react-focus-on';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';

import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';

import {Hamburger} from './Hamburger';
import type {NavigationType} from './Navbar';
import {NavLink} from './NavLink';

type Props = {
	navigation: NavigationType;
	footerLinks?: NavigationType;
	lead?: string;
	heading?: string;
	phoneNumber?: string;
	email?: string;
};

export const MobileNav: FC<Props> = ({
	navigation,
	heading,
	lead,
	phoneNumber,
	email,
	footerLinks,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = useCallback(() => {
		if (isOpen) {
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}
	}, [isOpen]);

	return (
		<FocusOn enabled={isOpen} onEscapeKey={handleClick} gapMode="padding">
			<nav className={clsx('isolate')}>
				{/**
				 * The clickarea should be at least 48x48px for accessibility
				 */}
				<Hamburger isOpen={isOpen} onClick={handleClick} />
				<AnimatePresence>
					{isOpen && (
						<div
							className={clsx(
								isOpen ? 'grid' : 'hidden',
								'-z-10',
								'md:grid-cols-2',
								'absolute',
								'bottom-0',
								'left-0',
								'top-0',
								'right-0',
								'h-dvh',
								'items-stretch',
								'overflow-hidden',
							)}
						>
							{/* Left block animated */}
							<motion.div
								initial={{translateY: '100%'}}
								animate={{translateY: 0}}
								transition={{
									duration: 0.3,
									delay: 0.1,
								}}
								exit={{translateY: '100%'}}
								className={clsx('hidden', 'md:block', 'bg-primary', 'pl-[5vw]')}
							>
								<div
									className={clsx(
										'max-w-[750px]',
										'h-full',
										'grid',
										'ml-auto',
										'text-beige-100',
										'pr-[5vw]',
										'pb-6',
										'pt-36',
									)}
								>
									<div className={clsx('max-w-[404px]')}>
										<Link
											href="/"
											onClick={handleClick}
											className={clsx('inline-block', 'mb-16')}
										>
											<span className="sr-only">Forside</span>
											<Image
												src="/logo/vaag-logo-circle.svg"
												alt=""
												width={95}
												height={95}
												className={clsx('shrink-0', 'animate-slowSpin')}
												sizes="95px"
											/>
										</Link>
										{heading && (
											<Heading
												size="medium"
												className={clsx(
													'text-secondary',
													'font-normal',
													'mb-12',
												)}
												asChild
											>
												<p>{heading}</p>
											</Heading>
										)}
										{lead && (
											<Paragraph size="large" className={clsx('mb-12')}>
												{lead}
											</Paragraph>
										)}
										{(email || phoneNumber) && (
											<div className={clsx('grid', 'gap-2')}>
												{email && (
													<span>
														<span className="sr-only">Epost </span>
														<Paragraph asChild size="large">
															<a
																href={`mailto:${email.replaceAll(' ', '')}`}
																className={clsx(
																	'hover:text-beige-100/50',
																	'transition-colors',
																)}
															>
																{email}
															</a>
														</Paragraph>
													</span>
												)}

												{phoneNumber && (
													<span>
														<span className="sr-only">Telefon </span>
														<Paragraph asChild size="large">
															<a
																href={createPhoneLink(phoneNumber)}
																className={clsx(
																	'hover:text-beige-100/50',
																	'transition-colors',
																)}
															>
																{formatPhoneNumber(phoneNumber)}
															</a>
														</Paragraph>
													</span>
												)}
											</div>
										)}
									</div>
								</div>
							</motion.div>

							{/* Right block animated */}
							<motion.div
								initial={{translateY: '-100%'}}
								animate={{translateY: 0}}
								transition={{
									duration: 0.3,
									delay: 0.1,
								}}
								exit={{translateY: '-100%'}}
								className={clsx('bg-secondary', 'h-full')}
							>
								<div
									className={clsx(
										'max-w-[750px]',
										'h-full',
										'grid',
										'justify-center',
										'px-6',
										'pb-6',
										'pt-16',
										'md:pt-72',
										'max-md:text-center',
									)}
								>
									<div className={clsx('flex', 'flex-col', 'justify-between')}>
										<div>
											<Link
												href="/"
												onClick={handleClick}
												className={clsx('inline-block', 'md:hidden')}
											>
												<span className="sr-only">Forside</span>
												<Image
													src="/logo/vaag-logo-circle-primary.svg"
													alt=""
													width={95}
													height={95}
													className={clsx(
														'shrink-0',
														'group-hover:rotate-180',
														'transition-transform',
														'ease-in',
														'duration-300',
														'mx-auto',
														'mb-12',
														'animate-slowSpin',
													)}
													sizes="95px"
												/>
											</Link>

											<ul
												className={clsx(
													'flex',
													'flex-col',
													'gap-8',
													'md:gap-16',
												)}
											>
												{navigation.map((item) => {
													if (item.type === 'navlink') {
														return (
															<li key={item._key}>
																<NavLink
																	link={item}
																	onClick={handleClick}
																/>
															</li>
														);
													}
												})}
											</ul>
										</div>

										{footerLinks?.length ? (
											<ul className={clsx('md:hidden', 'mb-6', 'space-y-2')}>
												{footerLinks?.map((link) => (
													<li key={link._key}>
														<Link
															href={link.href}
															onClick={handleClick}
															className={clsx(
																'text-base',
																'hover:text-primary-light',
																'transition-colors',
																'text-primary',
															)}
															{...(link.targetBlank
																? {
																		target: '_blank',
																		rel: 'noopener noreferrer',
																	}
																: {})}
														>
															{link.title}
														</Link>
													</li>
												))}
											</ul>
										) : null}
									</div>
								</div>
							</motion.div>
						</div>
					)}
				</AnimatePresence>
			</nav>
		</FocusOn>
	);
};
