import {internalLinkToHref, linkFragmentToHref} from '@/modules/foundation/sanity/link/query';

import type {FooterProps} from '../../components/Footer';
import type {SiteSettingsFragment} from '../query';

export const footerPropsFromSiteSettingsFragment = (
	siteSettings: SiteSettingsFragment,
): FooterProps => {
	const {organization, contactInfo, footerLinks, privacyPageReference} = siteSettings;

	return {
		organization: {
			organizationNumber: organization?.number,
			organizationName: organization?.name,
			address: contactInfo?.address,
			phoneNumber: contactInfo?.phoneNumber,
			email: contactInfo?.email,
		},
		links: footerLinks?.map((link) => ({
			_key: link._key,
			href: linkFragmentToHref(link),
			title: link.title,
			targetBlank: link.targetBlank,
		})),
		privacyPage: {
			title: privacyPageReference?.title,
			href: internalLinkToHref('page', privacyPageReference?.slug),
		},
	};
};
