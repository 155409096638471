import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';
import {twMerge} from 'tailwind-merge';

const paragraph = cva(['font-normal', 'font-sans'], {
	variants: {
		size: {
			xsmall: ['text-xs'],
			small: ['text-sm'],
			medium: ['text-base'],
			large: ['text-lg'],
			xlarge: ['text-xl', 'tracking-[-1.5%]'],
			xxlarge: ['text-2xl'],
		},
	},
});

type ParagraphProps = VariantProps<typeof paragraph> &
	ComponentPropsWithoutRef<'p'> & {
		// /** Adds margin-bottom */
		// spacing?: boolean;
		// /** Reduces line-height for short paragraphs
		//  * @deprecated Use `variant="short"` instead
		//  */
		// short?: boolean;
		// /** Adjusts styling for paragraph length */
		// variant?: 'long' | 'short';
		/**
		 * Change the default rendered element for the one passed as a child, merging their props and behavior.
		 * @default false
		 */
		asChild?: boolean;
	};

/** Use `Paragraph` to display text with paragraph text styles. */
export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
	(
		{
			size = 'medium',
			// spacing = false,
			className,
			asChild,
			...rest
		},
		ref,
	) => {
		const Component = asChild ? Slot : 'p';

		return <Component ref={ref} className={twMerge(paragraph({size, className}))} {...rest} />;
	},
);

Paragraph.displayName = 'Paragraph';
