'use client';

import type {PropsWithChildren} from 'react';
import {createContext, useContext, useState} from 'react';

type HeroContextType = {
	hasVideoHero: boolean;
	setHasVideoHero: (isPresent: boolean) => void;
};

const HeroContext = createContext<HeroContextType | undefined>(undefined);

export const HeroProvider = ({children}: PropsWithChildren) => {
	const [hasVideoHero, setHasVideoHero] = useState(false);

	return (
		<HeroContext.Provider value={{hasVideoHero, setHasVideoHero}}>
			{children}
		</HeroContext.Provider>
	);
};

export const useHero = () => {
	const context = useContext(HeroContext);
	if (!context) {
		throw new Error('useHero must be used within a HeroProvider');
	}
	return context;
};
