import clsx from 'clsx';
import Link from 'next/link';

import {Container} from '@/modules/foundation/components/container';
import {LogoDesktop} from '@/modules/foundation/components/logo/LogoDesktop';
import {LogoMobile} from '@/modules/foundation/components/logo/LogoMobile';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import {MobileNav} from './MobileNav';
import {NavbarContainer} from './NavbarContainer';
import type {NavLinkType} from './NavLink';

export type NavigationType = KeyedArray<NavLinkType>;

type NavbarProps = {
	navigation: NavigationType;
	heading?: string;
	lead?: string;
	phoneNumber?: string;
	email?: string;
	footerLinks?: NavigationType;
};

const FRONT_PAGE = 'Forside';

export const Navbar = ({
	navigation,
	heading,
	lead,
	phoneNumber,
	email,
	footerLinks,
}: NavbarProps) => {
	return (
		<div className="mb-24">
			<NavbarContainer>
				<Container as="header" className={clsx('bg-transparent')}>
					<div
						className={clsx('flex', 'h-24', 'items-center', 'justify-between', 'gap-4')}
					>
						<Link href="/">
							<span className={clsx('sr-only')}>{FRONT_PAGE}</span>
							<LogoDesktop
								aria-hidden="true"
								height={40}
								className={clsx('hidden', 'md:block')}
							/>
							<LogoMobile
								aria-hidden="true"
								height={40}
								className={clsx('md:hidden')}
							/>
						</Link>
						{navigation ? (
							<MobileNav
								navigation={navigation}
								heading={heading}
								lead={lead}
								phoneNumber={phoneNumber}
								email={email}
								footerLinks={footerLinks}
							/>
						) : null}
					</div>
				</Container>
			</NavbarContainer>
		</div>
	);
};
