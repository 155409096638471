'use client';

import type {FC} from 'react';

import {linkFragmentToHref} from '@/modules/foundation/sanity/link/query';
import {useQuery} from '@/sanity/loader/useQuery';

import {Navbar} from '../../components/Navbar';
import {SITE_SETTINGS_QUERY, type SiteSettingsQueryResult} from '../query';

import {navigationTypeFromSiteSettingsFragment} from './query';

type Props = {
	initial: SiteSettingsQueryResult;
};

export const NavbarDraft: FC<Props> = ({initial}) => {
	const {data: siteSettings} = useQuery<SiteSettingsQueryResult>(initial, SITE_SETTINGS_QUERY);

	if (!siteSettings) {
		return null;
	}

	return (
		<Navbar
			navigation={navigationTypeFromSiteSettingsFragment(siteSettings)}
			heading={siteSettings.navigationContent?.navigationHeading}
			lead={siteSettings.navigationContent?.navigationLead}
			phoneNumber={siteSettings.contactInfo?.phoneNumber}
			email={siteSettings.contactInfo?.email}
			footerLinks={siteSettings.footerLinks?.map((link) => ({
				type: 'navlink',
				_key: link._key,
				href: linkFragmentToHref(link),
				title: link.title ?? '',
				targetBlank: link.targetBlank,
			}))}
		/>
	);
};

export default NavbarDraft;
