import {linkFragmentToHref} from '@/modules/foundation/sanity/link/query';

import type {NavigationType} from '../../components/Navbar';
import type {SiteSettingsFragment} from '../query';

export function navigationTypeFromSiteSettingsFragment(
	siteSettings: SiteSettingsFragment,
): NavigationType {
	const navigation: NavigationType = [];

	if (!siteSettings.navigation) {
		return navigation;
	}

	for (const item of siteSettings.navigation) {
		if (item._type === 'link') {
			navigation.push({
				type: 'navlink',
				href: linkFragmentToHref(item),
				title: item.title ?? '',
				targetBlank: item.targetBlank,
				_key: item._key,
			});
		}
	}

	return navigation;
}
