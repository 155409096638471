'use client';

import {type FC} from 'react';
import {SanityProjectDetailsProvider} from '@selvklart/sanity-next-image';
import {MotionConfig} from 'framer-motion';

import {HeroProvider} from '@/modules/vaag/context/hero-context';

interface Props {
	children: React.ReactNode;
}

const projectDetails = {
	dataset: process.env.NEXT_PUBLIC_SANITY_DATASET ?? '',
	projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? '',
};

export const AppProvider: FC<Props> = ({children}) => {
	return (
		<MotionConfig reducedMotion="user">
			<HeroProvider>
				<SanityProjectDetailsProvider projectDetails={projectDetails}>
					{children}
				</SanityProjectDetailsProvider>
			</HeroProvider>
		</MotionConfig>
	);
};
