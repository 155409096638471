import type {FC} from 'react';
import clsx from 'clsx';

import {Link} from '@/modules/foundation/components/link';

export type NavLinkType = {
	type: 'navlink';
	title: string;
	href: string;
	targetBlank?: boolean;
};

type NavLinkProps = {
	link: NavLinkType;
	onClick?: () => void;
};

export const NavLink: FC<NavLinkProps> = ({link, onClick}) => {
	return (
		<Link
			onClick={onClick}
			href={link.href}
			targetBlank={link.targetBlank}
			className={clsx(
				'inline-block',
				'no-underline',
				'font-display',
				'text-primary',
				'text-[clamp(3rem,_7vw,_5rem)]',
				'font-bold',
				'hover:text-primary-light',
				'transition-colors',
			)}
		>
			{link.title}
		</Link>
	);
};
